<template>
	<div class="contract-history">
		<bg-text size="heading-2" class="mb-32">{{ xPropertyDetail.name }}</bg-text>
		<bg-text size="heading-4" class="mb-32">
			Riwayat Perubahan Kontrak
		</bg-text>

		<table class="ss-table mb-32">
			<thead>
				<tr>
					<th><bg-text size="title-5">Diubah oleh</bg-text></th>
					<th><bg-text size="title-5">Role</bg-text></th>
					<th><bg-text size="title-5">Data yang Diubah</bg-text></th>
					<th><bg-text size="title-5">Input Lama</bg-text></th>
					<th><bg-text size="title-5">Input Baru</bg-text></th>
					<th><bg-text size="title-5">Waktu Diubah</bg-text></th>
				</tr>
			</thead>

			<tbody>
				<template v-if="historyState === AsyncStates.LOADING">
					<tr v-for="i in 3" :key="i">
						<td v-for="i in 6" :key="i">
							<bg-skeleton :height="20" width="100%" />
						</td>
					</tr>
				</template>

				<template v-else-if="historyState === AsyncStates.SUCCESS">
					<tr v-for="(history, i) in histories" :key="`table-data-${i}`">
						<td>{{ history.actor_name }}</td>
						<td>{{ history.actor_role }}</td>
						<td>{{ history.field_name }}</td>
						<td>{{ history.old_value }}</td>
						<td>{{ history.new_value }}</td>
						<td>{{ history.formatted_created_at }}</td>
					</tr>
				</template>
			</tbody>
		</table>

		<div v-if="historyState === AsyncStates.NO_DATA" class="ta-c mt-16 mb-16">
			Belum ada data riwayat perubahan kontrak
		</div>

		<bg-pagination
			v-if="pagination.totalPage > 1"
			:value="pagination.page"
			:page-total="pagination.totalPage"
			@click="onPaginationChange"
		/>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { BgText, BgSkeleton, BgPagination } from 'bangul-vue';
import contractApi from '@admin_endpoints/contract-management';

const AsyncStates = {
	INITIAL: 'initial',
	LOADING: 'loading',
	SUCCESS: 'success',
	NO_DATA: 'no_data',
	ERROR: 'error',
};

export default {
	name: 'ContractHistory',

	components: {
		BgText,
		BgSkeleton,
		BgPagination,
	},

	data() {
		return {
			histories: [],
			historyState: AsyncStates.INITIAL,
			pagination: {
				page: 1,
				totalPage: 1,
				limit: 10,
			},
			AsyncStates,
		};
	},

	computed: {
		...mapState('propertyDetail', ['xPropertyDetail']),
		propertyId() {
			return this.$route.params.propertyId;
		},
	},

	created() {
		this.handleUpdateBreadcrumb();
		this.fetchHistoryContract();
	},

	methods: {
		...mapMutations('breadcrumb', ['updateBreadcrumb']),

		handleUpdateBreadcrumb() {
			this.updateBreadcrumb({
				index: 1,
				item: {
					name: this.xPropertyDetail.name,
					url: `/property-detail/${this.propertyId}/contract`,
				}
			});
		},

		async fetchHistoryContract() {
			const { page, limit } = this.pagination;
			const params = {
				limit,
				offset: (page - 1) * limit,
			};

			this.historyState = AsyncStates.LOADING;

			try {
				const { data } = await contractApi.getContractChangelog(
					this.propertyId,
					params
				);

				this.histories = data.changelogs;

				if (data.changelogs.length) this.historyState = AsyncStates.SUCCESS;
				else this.historyState = AsyncStates.NO_DATA;

				const totalData = Number(data.metadata.total);

				if (totalData) this.pagination.totalPage = Math.ceil(totalData / limit);
			} catch (error) {
				console.error(error);
				this.historyState = AsyncStates.ERROR;
				this.$toast.show('Terjadi kesalahan silahkan refresh halaman.');
			}
		},

		onPaginationChange(page) {
			this.pagination.page = page;
			this.fetchHistoryContract();
		},
	},
};
</script>

<style lang="scss" src="./ContractHistory.scss" scoped></style>
