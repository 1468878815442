<template>
  <div class="property-detail-changelog">
    <skeleton-loader
      v-if="isLoading"
      :columns="6"
      data-testid="propertyChangelogLoader"
    />
    <div v-else>
      <bg-text size="heading-2">{{ xPropertyDetail.name }}</bg-text>
      <bg-text size="heading-4" class="property-detail-changelog__title"
        >Riwayat Perubahan Detail Kos</bg-text
      >

      <table class="ss-table" data-testid="propertyChangelogTable">
        <thead>
          <tr class="property-detail-changelog__table-head">
            <th class="property-detail-changelog__table-col">Diubah Oleh</th>
            <th class="property-detail-changelog__table-col">Role</th>
            <th class="property-detail-changelog__table-col">
              Data yang Diubah
            </th>
            <th class="property-detail-changelog__table-col">Input Lama</th>
            <th class="property-detail-changelog__table-col">Input Baru</th>
            <th class="property-detail-changelog__table-col">Waktu Diubah</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, i) in changelogData.changelogs" :key="i">
            <td>{{ data.actor_name }}</td>
            <td>{{ data.actor_role }}</td>
            <td>
              {{ data.field_name }}<br />
              <bg-text size="label-2" italic>Event: {{ data.event }} </bg-text>
            </td>
            <td>{{ data.old_value }}</td>
            <td>{{ data.new_value }}</td>
            <td>{{ data.formatted_created_at }}</td>
          </tr>
          <tr
            v-if="
              !changelogData.changelogs || changelogData.changelogs.length === 0
            "
          >
            <td class="property-detail-changelog__empty-text" colspan="6">
              <bg-text size="body-2" italic
                >Belum ada riwayat perubahan kontrak</bg-text
              >
            </td>
          </tr>
        </tbody>
      </table>

      <bg-pagination
        data-testid="propertyChangelogPagination"
        v-if="hasPagination"
        v-model="currentPage"
        :page-total="totalPage"
        @click="handlePagination"
      />
    </div>
  </div>
</template>

<script>
import { BgText, BgPagination } from 'bangul-vue';
import { mapState, mapMutations } from 'vuex';
import propertyDetailApi from '@admin_endpoints/property-detail';
import SkeletonLoader from '../TenantTracker/components/SkeletonLoader';

export default {
  name: 'PropertyDetailChangelog',

  components: {
    BgText,
    BgPagination,
    SkeletonLoader,
  },

  computed: {
    ...mapState('propertyDetail', ['xLoadingState', 'xPropertyDetail']),

    hasPagination() {
      return this.changelogData?.metadata?.total > this.limitPage;
    },

    propertyId() {
      return this.$route.params.propertyId;
    },

    totalPage() {
      const total = this.changelogData?.metadata?.total || 0;
      return Math.ceil(total / this.limitPage);
    },
  },

  created() {
    this.handleBreadcrumb();
    this.consumePropertyChangelog();
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),

    handlePagination() {
      const offset = (+this.currentPage - 1) * this.limitPage;
      this.consumePropertyChangelog(offset);
    },

    async fetchPropertyChangelog(offset) {
      let result = {};
      try {
        result = await propertyDetailApi.getPropertyChangelog({
          propertyId: this.propertyId,
          limit: this.limitPage,
          offset,
        });
      } catch (error) {
        result.hasError = true;
        console.error(error);
        this.$toast.show('Terjadi kesalahan silakan refresh halaman.');
      }
      return result;
    },

    async consumePropertyChangelog(offset) {
      const offsetData = offset || 0;
      this.isLoading = true;
      const response = await this.fetchPropertyChangelog(offsetData);

      if (!response.hasError) {
        const { data } = response;
        this.changelogData = data;
      }
      this.isLoading = false;
    },

    handleBreadcrumb() {
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xPropertyDetail.name,
          url: `/property-detail/${this.propertyId}/kos`,
        },
      });
    },
  },

  data() {
    return {
      isLoading: false,
      changelogData: [],
      limitPage: 10,
      currentPage: 1,
    };
  },
};
</script>

<style lang="scss" scoped src="./PropertyDetailChangelog.scss"></style>
