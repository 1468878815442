<template>
  <div class="property-detail-contract">
    <owner-profile
      :owner="owner"
      :loading="loading.ownerProfile"
      :property-id="propertyId"
    />

    <disbursement-information
      :disbursement="disbursement"
      :loading="loading.disbursementPartnership"
    />

    <partnership-detail
      :detail="partnership"
      :loading="loading.disbursementPartnership"
      :property-id="propertyId"
      :labels="labels"
      :class-upgrade="classUpgrade"
      @auto-renewal="handleToggleAutoRenewal"
      @class-upgrade-changed="fetchDisbursementAndPartnership"
    />

    <property-additional-fee
      :loading="loading.disbursementPartnership"
      class="mb-24"
    />

    <room-price
      :data="priceList.listing"
      :loading="loading.priceList"
      class="mb-24"
    />
  </div>
</template>

<script>
import cooperationContractApi from '@admin_endpoints/cooperation-contract';
import propertyApi from '@admin_endpoints/property-detail';

import {
  CLASS_UPGRADE_INITIAL_STATE,
  composeClassUpgradeData
} from './utils/property-class-upgrade';

export default {
  name: 'PropertyDetailContract',

  components: {
    OwnerProfile: () => import('./partials/OwnerProfile'),
    PropertyAdditionalFee: () => import('./partials/PropertyAdditionalFee'),
    RoomPrice: () => import('@admin_organisms/RoomPrice'),
    DisbursementInformation: () => import('./partials/DisbursementInformation'),
    PartnershipDetail: () => import('./partials/PartnershipDetail'),
  },

  computed: {
    propertyId() {
      return this.$route.params.propertyId;
    },
  },

  data() {
    return {
      loading: {
        priceList: false,
        ownerProfile: false,
        disbursementPartnership: false,
      },
      priceList: {},
      owner: {},
      disbursement: {},
      partnership: {},
      labels: {},
      classUpgrade: CLASS_UPGRADE_INITIAL_STATE,
    };
  },

  created() {
    this.consumeListingPriceList();
    this.fetchOwnerData();
    this.fetchDisbursementAndPartnership();

    const toastMessage = this.$route.meta?.toastMessage || '';

    if (toastMessage) {
      this.$toast.show(this.$route.meta.toastMessage, { duration: 8 });
    }
  },

  methods: {
    async fetchListingPriceList() {
      let result = {};
      try {
        result = await cooperationContractApi.getListingPriceList(
          this.propertyId
        );
      } catch (error) {
        result.hasError = true;
        this.$toast.show(result, { duration: 8 });
        console.error(error);
      }
      return result;
    },

    async consumeListingPriceList() {
      this.loading.priceList = true;
      const response = await this.fetchListingPriceList();

      if (!response.hasError) {
        const { data } = response.data;
        this.priceList = data;
      }

      this.loading.priceList = false;
    },

    async fetchOwnerData() {
      this.loading.ownerProfile = true;

      try {
        const { data } = await propertyApi.getOwnerProfile(this.propertyId);

        if (data.status) {
          this.owner = data.data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading.ownerProfile = false;
      }
    },

    async fetchDisbursementAndPartnership() {
      this.loading.disbursementPartnership = true;

      try {
        const { data } = await propertyApi.getDisbursementAndPartnership(
          this.propertyId
        );

        if (data.status) {
          this.disbursement = data.data.transfer_info;
          this.partnership = data.data.detail;
          this.labels = data.data.label || {};

          this.classUpgrade = composeClassUpgradeData({
            classUpgrade: data.data.class_upgrade,
            roomTotal: this.partnership.room_total
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading.disbursementPartnership = false;
      }
    },

    async handleToggleAutoRenewal({ action }, callback) {
      const isActivate = action === 'activate';
      const contractId = this.partnership.id;
      try {
        const response = isActivate
          ? await propertyApi.activateAutoRenewal(contractId)
          : await propertyApi.deactivateAutoRenewal(contractId);

        if (response) {
          this.$toast.show(
            `Perpanjang Kontrak Otomatis ${
              isActivate ? 'diaktifkan' : 'dinonaktifkan'
            }`
          );
        }
      } catch (error) {
        this.$toast.show(
          error?.response?.data?.error ||
            `Gagal ${
              isActivate ? 'mengaktifkan' : 'menonaktifkan'
            } Perpanjang Kontrak Otomatis`
        );
      } finally {
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyDetailContract.scss"></style>
