import api from '@admin/api/service';

export default {
  getListingPriceList(propertyId) {
    return api.get(`/singgahsini/api/property/${propertyId}/listing-price`);
  },

  storeAdditionalCost(propertyId, data) {
    return api.post(
      `singgahsini/api/property/${propertyId}/additional-cost`,
      data
    );
  },

  updateAdditionalCost(propertyId, costId, data) {
    return api.put(
      `singgahsini/api/property/${propertyId}/additional-cost/${costId}`,
      data
    );
  },

  deleteAdditionalCost(propertyId, costId) {
    return api.delete(
      `singgahsini/api/property/${propertyId}/additional-cost/${costId}`
    );
  },
};
